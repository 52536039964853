@font-face {
  font-family: around-icons;
  src: url(around-icons.woff2?29da402db6a4645f2ed69d761c35b60e) format('woff2');
}
[class*=' ai-']:before,
[class^='ai-']:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: around-icons !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 400 !important;
  line-height: 1;
  text-transform: none;
}
.ai-activity:before {
  content: '\f101';
}
.ai-airbnb:before {
  content: '\f102';
}
.ai-align-center:before {
  content: '\f103';
}
.ai-align-justify:before {
  content: '\f104';
}
.ai-align-left:before {
  content: '\f105';
}
.ai-align-right:before {
  content: '\f106';
}
.ai-anchor:before {
  content: '\f107';
}
.ai-apple:before {
  content: '\f108';
}
.ai-archive:before {
  content: '\f109';
}
.ai-arrow-down-left:before {
  content: '\f10a';
}
.ai-arrow-down-right:before {
  content: '\f10b';
}
.ai-arrow-down:before {
  content: '\f10c';
}
.ai-arrow-left:before {
  content: '\f10d';
}
.ai-arrow-right:before {
  content: '\f10e';
}
.ai-arrow-up-left:before {
  content: '\f10f';
}
.ai-arrow-up-right:before {
  content: '\f110';
}
.ai-arrow-up:before {
  content: '\f111';
}
.ai-at:before {
  content: '\f112';
}
.ai-award:before {
  content: '\f113';
}
.ai-bar-chart-1:before {
  content: '\f114';
}
.ai-bar-chart-2:before {
  content: '\f115';
}
.ai-bar-chart-3:before {
  content: '\f116';
}
.ai-battery-1:before {
  content: '\f117';
}
.ai-battery-2:before {
  content: '\f118';
}
.ai-battery-3:before {
  content: '\f119';
}
.ai-battery-charge:before {
  content: '\f11a';
}
.ai-battery:before {
  content: '\f11b';
}
.ai-behance:before {
  content: '\f11c';
}
.ai-bell-minus:before {
  content: '\f11d';
}
.ai-bell-off:before {
  content: '\f11e';
}
.ai-bell-plus:before {
  content: '\f11f';
}
.ai-bell:before {
  content: '\f120';
}
.ai-blogger:before {
  content: '\f121';
}
.ai-bluetooth:before {
  content: '\f122';
}
.ai-body:before {
  content: '\f123';
}
.ai-book:before {
  content: '\f124';
}
.ai-bookmark-minus:before {
  content: '\f125';
}
.ai-bookmark-plus:before {
  content: '\f126';
}
.ai-bookmark:before {
  content: '\f127';
}
.ai-box:before {
  content: '\f128';
}
.ai-briefcase-plus:before {
  content: '\f129';
}
.ai-briefcase:before {
  content: '\f12a';
}
.ai-bulb-alt:before {
  content: '\f12b';
}
.ai-bulb:before {
  content: '\f12c';
}
.ai-cafe:before {
  content: '\f12d';
}
.ai-calendar-check:before {
  content: '\f12e';
}
.ai-calendar-minus:before {
  content: '\f12f';
}
.ai-calendar-plus:before {
  content: '\f130';
}
.ai-calendar-x:before {
  content: '\f131';
}
.ai-calendar:before {
  content: '\f132';
}
.ai-camera-off:before {
  content: '\f133';
}
.ai-camera:before {
  content: '\f134';
}
.ai-canteen:before {
  content: '\f135';
}
.ai-capsule:before {
  content: '\f136';
}
.ai-card:before {
  content: '\f137';
}
.ai-cart:before {
  content: '\f138';
}
.ai-cast:before {
  content: '\f139';
}
.ai-chart:before {
  content: '\f13a';
}
.ai-chat:before {
  content: '\f13b';
}
.ai-check-alt:before {
  content: '\f13c';
}
.ai-check:before {
  content: '\f13d';
}
.ai-checks:before {
  content: '\f13e';
}
.ai-chevron-down:before {
  content: '\f13f';
}
.ai-chevron-left:before {
  content: '\f140';
}
.ai-chevron-right:before {
  content: '\f141';
}
.ai-chevron-up:before {
  content: '\f142';
}
.ai-chevrons-down:before {
  content: '\f143';
}
.ai-chevrons-left:before {
  content: '\f144';
}
.ai-chevrons-right:before {
  content: '\f145';
}
.ai-chevrons-up:before {
  content: '\f146';
}
.ai-chip:before {
  content: '\f147';
}
.ai-circle-alert:before {
  content: '\f148';
}
.ai-circle-arrow-down:before {
  content: '\f149';
}
.ai-circle-arrow-left:before {
  content: '\f14a';
}
.ai-circle-arrow-right:before {
  content: '\f14b';
}
.ai-circle-arrow-up:before {
  content: '\f14c';
}
.ai-circle-check-filled:before {
  content: '\f14d';
}
.ai-circle-check:before {
  content: '\f14e';
}
.ai-circle-help:before {
  content: '\f14f';
}
.ai-circle-info:before {
  content: '\f150';
}
.ai-circle-minus:before {
  content: '\f151';
}
.ai-circle-plus:before {
  content: '\f152';
}
.ai-circle-slash:before {
  content: '\f153';
}
.ai-circle-x:before {
  content: '\f154';
}
.ai-clipboard:before {
  content: '\f155';
}
.ai-clock:before {
  content: '\f156';
}
.ai-cloud-download:before {
  content: '\f157';
}
.ai-cloud-drizzle:before {
  content: '\f158';
}
.ai-cloud-lightning:before {
  content: '\f159';
}
.ai-cloud-rain:before {
  content: '\f15a';
}
.ai-cloud-snow:before {
  content: '\f15b';
}
.ai-cloud-upload:before {
  content: '\f15c';
}
.ai-cloud:before {
  content: '\f15d';
}
.ai-code-curly:before {
  content: '\f15e';
}
.ai-code:before {
  content: '\f15f';
}
.ai-codepen:before {
  content: '\f160';
}
.ai-collapse:before {
  content: '\f161';
}
.ai-command:before {
  content: '\f162';
}
.ai-compass:before {
  content: '\f163';
}
.ai-copy:before {
  content: '\f164';
}
.ai-crop:before {
  content: '\f165';
}
.ai-cross-alt:before {
  content: '\f166';
}
.ai-cross:before {
  content: '\f167';
}
.ai-crosshair:before {
  content: '\f168';
}
.ai-cup-hot:before {
  content: '\f169';
}
.ai-cup:before {
  content: '\f16a';
}
.ai-dashboard:before {
  content: '\f16b';
}
.ai-database:before {
  content: '\f16c';
}
.ai-delete:before {
  content: '\f16d';
}
.ai-disc:before {
  content: '\f16e';
}
.ai-discord:before {
  content: '\f16f';
}
.ai-discount:before {
  content: '\f170';
}
.ai-dislike:before {
  content: '\f171';
}
.ai-disqus:before {
  content: '\f172';
}
.ai-dollar:before {
  content: '\f173';
}
.ai-dots-horizontal:before {
  content: '\f174';
}
.ai-dots-vertical:before {
  content: '\f175';
}
.ai-dots:before {
  content: '\f176';
}
.ai-download:before {
  content: '\f177';
}
.ai-drawer:before {
  content: '\f178';
}
.ai-dribbble:before {
  content: '\f179';
}
.ai-drop:before {
  content: '\f17a';
}
.ai-dropbox:before {
  content: '\f17b';
}
.ai-droplet:before {
  content: '\f17c';
}
.ai-edit-alt:before {
  content: '\f17d';
}
.ai-edit:before {
  content: '\f17e';
}
.ai-emoji-happy:before {
  content: '\f17f';
}
.ai-emoji-meh:before {
  content: '\f180';
}
.ai-emoji-sad:before {
  content: '\f181';
}
.ai-emoji-smile:before {
  content: '\f182';
}
.ai-emoji-surprise:before {
  content: '\f183';
}
.ai-evernote:before {
  content: '\f184';
}
.ai-expand:before {
  content: '\f185';
}
.ai-external-link:before {
  content: '\f186';
}
.ai-facebook:before {
  content: '\f187';
}
.ai-fast-forward:before {
  content: '\f188';
}
.ai-file-minus:before {
  content: '\f189';
}
.ai-file-plus:before {
  content: '\f18a';
}
.ai-file-text:before {
  content: '\f18b';
}
.ai-file:before {
  content: '\f18c';
}
.ai-filter:before {
  content: '\f18d';
}
.ai-flag:before {
  content: '\f18e';
}
.ai-flickr:before {
  content: '\f18f';
}
.ai-flower:before {
  content: '\f190';
}
.ai-folder-minus:before {
  content: '\f191';
}
.ai-folder-plus:before {
  content: '\f192';
}
.ai-folder:before {
  content: '\f193';
}
.ai-foursquare:before {
  content: '\f194';
}
.ai-fruit:before {
  content: '\f195';
}
.ai-gift:before {
  content: '\f196';
}
.ai-github:before {
  content: '\f197';
}
.ai-globe:before {
  content: '\f198';
}
.ai-google-drive:before {
  content: '\f199';
}
.ai-google-play:before {
  content: '\f19a';
}
.ai-google:before {
  content: '\f19b';
}
.ai-grid:before {
  content: '\f19c';
}
.ai-hangouts:before {
  content: '\f19d';
}
.ai-hard-drive:before {
  content: '\f19e';
}
.ai-hashtag:before {
  content: '\f19f';
}
.ai-headphone:before {
  content: '\f1a0';
}
.ai-heart-alt:before {
  content: '\f1a1';
}
.ai-heart-filled:before {
  content: '\f1a2';
}
.ai-heart:before {
  content: '\f1a3';
}
.ai-help:before {
  content: '\f1a4';
}
.ai-hide:before {
  content: '\f1a5';
}
.ai-home:before {
  content: '\f1a6';
}
.ai-horizontal-align-center:before {
  content: '\f1a7';
}
.ai-horizontal-align-left:before {
  content: '\f1a8';
}
.ai-horizontal-align-right:before {
  content: '\f1a9';
}
.ai-image:before {
  content: '\f1aa';
}
.ai-inbox:before {
  content: '\f1ab';
}
.ai-instagram:before {
  content: '\f1ac';
}
.ai-joystick:before {
  content: '\f1ad';
}
.ai-key:before {
  content: '\f1ae';
}
.ai-kickstarter:before {
  content: '\f1af';
}
.ai-layer:before {
  content: '\f1b0';
}
.ai-layout-column:before {
  content: '\f1b1';
}
.ai-layout-grid:before {
  content: '\f1b2';
}
.ai-layout-row:before {
  content: '\f1b3';
}
.ai-layout-side:before {
  content: '\f1b4';
}
.ai-like:before {
  content: '\f1b5';
}
.ai-link:before {
  content: '\f1b6';
}
.ai-linkedin:before {
  content: '\f1b7';
}
.ai-list:before {
  content: '\f1b8';
}
.ai-lock-closed:before {
  content: '\f1b9';
}
.ai-lock-open:before {
  content: '\f1ba';
}
.ai-login:before {
  content: '\f1bb';
}
.ai-logout:before {
  content: '\f1bc';
}
.ai-mail-filled:before {
  content: '\f1bd';
}
.ai-mail:before {
  content: '\f1be';
}
.ai-map-pin:before {
  content: '\f1bf';
}
.ai-map:before {
  content: '\f1c0';
}
.ai-maximize:before {
  content: '\f1c1';
}
.ai-medium:before {
  content: '\f1c2';
}
.ai-menu:before {
  content: '\f1c3';
}
.ai-message-minus:before {
  content: '\f1c4';
}
.ai-message-plus:before {
  content: '\f1c5';
}
.ai-message:before {
  content: '\f1c6';
}
.ai-messages:before {
  content: '\f1c7';
}
.ai-messenger:before {
  content: '\f1c8';
}
.ai-microphone-off:before {
  content: '\f1c9';
}
.ai-microphone:before {
  content: '\f1ca';
}
.ai-minimize:before {
  content: '\f1cb';
}
.ai-mobile:before {
  content: '\f1cc';
}
.ai-monitor:before {
  content: '\f1cd';
}
.ai-moon:before {
  content: '\f1ce';
}
.ai-movie:before {
  content: '\f1cf';
}
.ai-music:before {
  content: '\f1d0';
}
.ai-note:before {
  content: '\f1d1';
}
.ai-octagon-alert:before {
  content: '\f1d2';
}
.ai-octagon:before {
  content: '\f1d3';
}
.ai-odnoklassniki:before {
  content: '\f1d4';
}
.ai-open-book:before {
  content: '\f1d5';
}
.ai-paint-roll:before {
  content: '\f1d6';
}
.ai-paperclip:before {
  content: '\f1d7';
}
.ai-parking:before {
  content: '\f1d8';
}
.ai-patreon:before {
  content: '\f1d9';
}
.ai-pause:before {
  content: '\f1da';
}
.ai-paypal:before {
  content: '\f1db';
}
.ai-pen:before {
  content: '\f1dc';
}
.ai-pencil:before {
  content: '\f1dd';
}
.ai-phone-call:before {
  content: '\f1de';
}
.ai-phone-in:before {
  content: '\f1df';
}
.ai-phone-out:before {
  content: '\f1e0';
}
.ai-phone-x:before {
  content: '\f1e1';
}
.ai-phone:before {
  content: '\f1e2';
}
.ai-pie-chart:before {
  content: '\f1e3';
}
.ai-pin:before {
  content: '\f1e4';
}
.ai-pinterest:before {
  content: '\f1e5';
}
.ai-planet:before {
  content: '\f1e6';
}
.ai-play-filled:before {
  content: '\f1e7';
}
.ai-play-next:before {
  content: '\f1e8';
}
.ai-play-previous:before {
  content: '\f1e9';
}
.ai-play:before {
  content: '\f1ea';
}
.ai-plug:before {
  content: '\f1eb';
}
.ai-plus:before {
  content: '\f1ec';
}
.ai-power:before {
  content: '\f1ed';
}
.ai-printer:before {
  content: '\f1ee';
}
.ai-pulse:before {
  content: '\f1ef';
}
.ai-quora:before {
  content: '\f1f0';
}
.ai-quotes:before {
  content: '\f1f1';
}
.ai-reddit:before {
  content: '\f1f2';
}
.ai-redo:before {
  content: '\f1f3';
}
.ai-refresh:before {
  content: '\f1f4';
}
.ai-repeat:before {
  content: '\f1f5';
}
.ai-rewind:before {
  content: '\f1f6';
}
.ai-rocket:before {
  content: '\f1f7';
}
.ai-rotate-left:before {
  content: '\f1f8';
}
.ai-rotate-right:before {
  content: '\f1f9';
}
.ai-rss:before {
  content: '\f1fa';
}
.ai-rug:before {
  content: '\f1fb';
}
.ai-save:before {
  content: '\f1fc';
}
.ai-scan:before {
  content: '\f1fd';
}
.ai-search-settings:before {
  content: '\f1fe';
}
.ai-search:before {
  content: '\f1ff';
}
.ai-send:before {
  content: '\f200';
}
.ai-server:before {
  content: '\f201';
}
.ai-settings-filled:before {
  content: '\f202';
}
.ai-settings:before {
  content: '\f203';
}
.ai-share:before {
  content: '\f204';
}
.ai-shield:before {
  content: '\f205';
}
.ai-shopping-bag:before {
  content: '\f206';
}
.ai-show:before {
  content: '\f207';
}
.ai-shower:before {
  content: '\f208';
}
.ai-shuffle:before {
  content: '\f209';
}
.ai-skype:before {
  content: '\f20a';
}
.ai-slack:before {
  content: '\f20b';
}
.ai-slider:before {
  content: '\f20c';
}
.ai-snapchat:before {
  content: '\f20d';
}
.ai-sofa:before {
  content: '\f20e';
}
.ai-soundcloud:before {
  content: '\f20f';
}
.ai-spa:before {
  content: '\f210';
}
.ai-space:before {
  content: '\f211';
}
.ai-speaker:before {
  content: '\f212';
}
.ai-spotify:before {
  content: '\f213';
}
.ai-square-info:before {
  content: '\f214';
}
.ai-square-minus:before {
  content: '\f215';
}
.ai-square-plus:before {
  content: '\f216';
}
.ai-square-x:before {
  content: '\f217';
}
.ai-square:before {
  content: '\f218';
}
.ai-stack-overflow:before {
  content: '\f219';
}
.ai-stairs:before {
  content: '\f21a';
}
.ai-star-filled:before {
  content: '\f21b';
}
.ai-star-half:before {
  content: '\f21c';
}
.ai-star:before {
  content: '\f21d';
}
.ai-steam:before {
  content: '\f21e';
}
.ai-sticker:before {
  content: '\f21f';
}
.ai-stones:before {
  content: '\f220';
}
.ai-sun:before {
  content: '\f221';
}
.ai-table-column:before {
  content: '\f222';
}
.ai-table-row:before {
  content: '\f223';
}
.ai-table:before {
  content: '\f224';
}
.ai-tablet:before {
  content: '\f225';
}
.ai-tag:before {
  content: '\f226';
}
.ai-target:before {
  content: '\f227';
}
.ai-telegram:before {
  content: '\f228';
}
.ai-terminal:before {
  content: '\f229';
}
.ai-tiktok:before {
  content: '\f22a';
}
.ai-time:before {
  content: '\f22b';
}
.ai-tinder:before {
  content: '\f22c';
}
.ai-toggle-left:before {
  content: '\f22d';
}
.ai-toggle-right:before {
  content: '\f22e';
}
.ai-tool:before {
  content: '\f22f';
}
.ai-towel:before {
  content: '\f230';
}
.ai-trash:before {
  content: '\f231';
}
.ai-trello:before {
  content: '\f232';
}
.ai-triangle-alert:before {
  content: '\f233';
}
.ai-triangle:before {
  content: '\f234';
}
.ai-tumblr:before {
  content: '\f235';
}
.ai-twitch:before {
  content: '\f236';
}
.ai-undo:before {
  content: '\f237';
}
.ai-upload:before {
  content: '\f238';
}
.ai-user-check:before {
  content: '\f239';
}
.ai-user-group:before {
  content: '\f23a';
}
.ai-user-minus:before {
  content: '\f23b';
}
.ai-user-plus:before {
  content: '\f23c';
}
.ai-user-x:before {
  content: '\f23d';
}
.ai-user:before {
  content: '\f23e';
}
.ai-vertical-align-bottom:before {
  content: '\f23f';
}
.ai-vertical-align-center:before {
  content: '\f240';
}
.ai-vertical-align-top:before {
  content: '\f241';
}
.ai-viber:before {
  content: '\f242';
}
.ai-video-off:before {
  content: '\f243';
}
.ai-video:before {
  content: '\f244';
}
.ai-vimeo:before {
  content: '\f245';
}
.ai-vk:before {
  content: '\f246';
}
.ai-volume-1:before {
  content: '\f247';
}
.ai-volume-2:before {
  content: '\f248';
}
.ai-volume-x:before {
  content: '\f249';
}
.ai-volume:before {
  content: '\f24a';
}
.ai-vote-down:before {
  content: '\f24b';
}
.ai-vote-up:before {
  content: '\f24c';
}
.ai-wallet:before {
  content: '\f24d';
}
.ai-wechat:before {
  content: '\f24e';
}
.ai-weight:before {
  content: '\f24f';
}
.ai-whatsapp:before {
  content: '\f250';
}
.ai-wifi-off:before {
  content: '\f251';
}
.ai-wifi:before {
  content: '\f252';
}
.ai-wordpress:before {
  content: '\f253';
}
.ai-x:before {
  content: '\f254';
}
.ai-xing:before {
  content: '\f255';
}
.ai-yelp:before {
  content: '\f256';
}
.ai-youtube:before {
  content: '\f257';
}
.ai-zoom-in:before {
  content: '\f258';
}
.ai-zoom-out:before {
  content: '\f259';
}
.ai-zoom:before {
  content: '\f25a';
}
.ai-credit-card:before {
  content: "\f1aa";
}

.ai-angle-left:before {
  content: "\276E";
}
.ai-angle-right:before {
  content: "\276F";
}