@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
// ... or
@import "../node_modules/@ng-select/ng-select/themes/material.theme.css";
@import "../src/assets/scss/theme.scss";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
.ngx-datatable {
  overflow: visible;
  margin-bottom: 1rem;
  &.material {
    // box-shadow: 0 10px 15px 0  #e9ecee;
    box-shadow: none !important;
    border-radius: 5px;
    .datatable-header {
      background-color: #448c74;
      .datatable-row-right {
        background-image: none;
        background-color: #448c74;
        // border-left: 1px solid $blue-grey-lighten-5;
      }

      .datatable-header-cell {
        color: #fff;
        font-weight: 500;
        font-size: 0.8rem;
        background-color: #448c74;
      }
    }
    .datatable-body {
      .datatable-body-cell {
        padding: 0.3rem 0.5rem;
        box-shadow: inset 1px 1px 0 #e9ecee;
      }
      .datatable-row-right {
        background-image: none;
        // border-left: 1px solid $blue-grey-lighten-5;
        .datatable-body-cell {
          padding: 0.5rem 0.6rem !important;
        }
        .datatable-body-cell-label {
          width: 100%;
        }
      }
      .datatable-row-left {
        background-image: none;
      }
      // .datatable-row-detail {
      //   width: 100vw !important;
      // }
    }
    .datatable-footer .page-count {
      color: #448c74;
    }
    .action-button {
      a {
        margin: 0 2px;
      }
    }
    &.single-selection {
      .datatable-body-row {
        &.active {
          background-color: #e9ecee;
          &:hover {
            background-color: #e9ecee;
            .datatable-row-group {
              background-color: #e9ecee;
            }
          }
          .datatable-row-group {
            background-color: #e9ecee;
            &:hover {
              background-color: #e9ecee;
            }
          }
        }
      }
    }
    &.content-center {
      .datatable-header {
        .datatable-header-cell {
          display: flex;

          justify-content: center;
        }
      }

      .datatable-scroll {
        // display: inherit !important;
        .datatable-body-row {
          .datatable-body-cell {
            justify-content: center;
          }
        }
      }
      //   .datatable-row-right:after {
      //     content: ' ';
      //     width: 17px;
      //     height: 35px;
      //     background: #ffffff;
      //     position: absolute;
      //     z-index: 333;
      //     box-shadow: inset 0 1px 0 #e9ecee;
      //     right: -17px;
      // }
    }
  }
  &.fixed-row {
    .datatable-scroll {
      // display: inherit !important;
      .datatable-body-row {
        .datatable-body-cell {
          padding: 0.3rem 0.5rem;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.font-400 {
    font-weight: 400;
}
.font-500 {
    font-weight: 500;
}
.font-600 {
    font-weight: 600;
}
.font-700 {
    font-weight: 700;
}
.font-800 {
    font-weight: 800;
}
.font-900 {
    font-weight: 900;
}
.font-10px {
    font-size: 10px;
}
.font-12px {
    font-size: 12px;
}
.font-14px {
    font-size: 14px;
}
.font-16px {
    font-size: 16px;
}
.font-18px {
    font-size: 18px;
}
.font-20px {
    font-size: 20px;
}
.font-20px {
    font-size: 20px;
}
.font-22px {
    font-size: 22px;
}
.font-24px {
    font-size: 24px;
}
.appointment-timer {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100vw;
    background-color: rgb(223, 240, 237);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 16px;
}
.customer-card {
    max-width: 450px;
}
.shield-sm {
    position: absolute;
    top:-90px;
    left: 0;
    right: 0;
    width: 200px;
    margin: auto;
}

.ng-select {
    border-radius: 1rem !important; /* Rounded corners (Bootstrap 5 default) */
    border: 0px solid #ced4da !important; /* Border color matching Bootstrap 5 */
    padding: 0 !important;

  }

  .ng-select .ng-select-container {
    padding: 0.825rem 1rem !important; /* Padding to match Bootstrap 5 input padding */
  }

  .ng-select .ng-select-container.ng-select-focused {
    background-color: transparent;
    border-color: var(--ar-gray-700);/* Bootstrap 5 primary color for focus */
  }
  .ng-select .ng-select-container {
    background-color: var(--ar-body-bg) !important;
  }
  .ng-select .ng-select-container .ng-value {
    color:var(--ar-gray-700) !important;
    /* Text color matching Bootstrap 5 */
  }

  .ng-select .ng-select-container .ng-placeholder {
    color: #6c757d; /* Placeholder color matching Bootstrap 5 */
  }

  .ng-select .ng-dropdown-panel {
    border-radius: 0.25rem; /* Rounded corners */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); /* Bootstrap 5 shadow */
  }
  .ng-dropdown-panel {
    z-index: 99999 !important;
  }
  .ng-select>.ng-select-container {
    border-radius: 1rem !important;
}
.ng-select .ng-select-container .ng-value-container {
    align-items: stretch;
    border-top: 0em solid transparent !important;
    // top: -7px;
    // position: relative;
    // left: -15px;
    // padding: 16px 0!important;

}
.ng-select .ng-select-container.ng-has-value .ng-value-container {
    align-items: stretch;
    border-top: 0em solid transparent !important;
    top: -7px;
    position: relative;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px !important;
}
.ng-select.ng-select-single .ng-select-container {
    height: 47.5px !important;
    min-height: 47.5px;
}
.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;
    bottom: -3px !important;
}
.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: -3px !important;
}
.ng-select .ng-select-container:after {
    border: 0;
}
.ng-select.ng-select-focused {
    border: 0px solid #576071 !important;
}
.ng-select.ng-select-focused {
    border: 0px solid #576071 !important;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: #576071 !important;
    box-shadow:none !important;
}
.ng-select.ng-select-single .ng-select-container.ng-has-value .ng-value-container .ng-input {
    padding-left: 0;
}
.ng-select.ng-select-single .ng-select-container.ng-has-value .ng-value-container .ng-input {
    top: 7px !important;
}
.btn-icon {
    width: 32px !important;
    height: 32px !important;
    border-radius: 6px !important;
}
// Table
.table thead th{
    font-weight: 600;
    text-align: inherit;
    text-align: -webkit-match-parent;
    background-color: #448c74 !important;
    color: #fff !important;
    position: relative;
    &:first-child {
        border-radius: 8px 0 0 0;
        border-left: 0;
    }
    &:last-child {
        border-radius:0 8px 0 0;
        border-right: 0;
    }
}
.table thead tr {
    border: 0 !important;
}
.my-select-badge {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    -webkit-appearance: none;
    option {
        background-color: #fff;
        color: #000;
    }
}

ngx-stripe-card-number {
    iframe {
        .ElementsApp, .ElementsApp .InputElement {
            color: #ffffffb3 !important;
        }
    }
}
.dp-calendar-day.dp-selected.dp-current-month {
  background: #106cc8 !important;
  color: #fff;
}
.dp-popup,
dp-day-calendar.dp-material .dp-calendar-month, dp-day-calendar.dp-material .dp-calendar-day {
  background: var(--ar-body-bg) !important;
}
dp-date-picker,
dp-month-calendar,
dp-day-time-calendar {
  position: relative;
  z-index: 9999999;
  &.dp-material.ccm-date-picker {
    dp-time-select {
      border: none;
    }
  }
  .ccm-date-picker {
    border: none;
  }
  .dp-popup {
    top: 5px;
    background:var(--ar-body-bg) !important;
    border-radius: 5px;

  }
}
dp-time-select .dp-time-select-controls {
  background:var(--ar-body-bg) !important;
}
dp-day-calendar {
  &.dp-material {
    &.ccm-date-picker {
      .dp-day-calendar-container {
        border-radius: 5px;
        // box-shadow: $box-shadow;
      }
      .dp-calendar-wrapper {
        background:  var(--ar-body-bg) !important;
        border: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0.7rem;
        overflow: hidden;
        .dp-calendar-weekday {
          width: 30px;
          color: #448c74;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 12px;
          margin: 3px;
        }
        .dp-calendar-day {
          width: 30px;
          height: 30px;
          font-size: 14px;
          margin: 3px;
        }
      }

      dp-calendar-nav {
        .dp-current-location-btn {
          background: #448c74;
          border: 1px solid #448c74;
          top: 0;
        }
        .dp-calendar-nav-container {
          height: 30px + 10;
          border: none;
          background: #448c74;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          overflow: hidden;
          .dp-nav-header {
            left: 0;
            .dp-nav-header-btn {
              height: 30px;
              background: #448c74;
              color: #fff;
            }
          }
        }

        .dp-nav-btns-container {
          right: 0;
          height: 30px;
          .dp-calendar-nav-container-left,
          .dp-calendar-nav-container-right {
            height: 30px;
          }
          .dp-calendar-nav-right,
          .dp-calendar-nav-left {
            display: inline-block;
            height: 100%;
            width: 30px;
            background: #448c74;
            color: #fff;
          }
        }
      }
    }
  }
}
dp-time-select {
  .dp-time-select-controls {
    border-radius: 5px;
    overflow: hidden;
    .dp-time-select-control-up::before {
      top: 1px;
    }
    .dp-time-select-control-down::before {
      top: -1px;
    }
  }
}
::ng-deep {
  .cdk-overlay-container {
    /* Do you changes here */
    position: fixed;
    z-index: 99999 !important;
}
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    .cdk-overlay-pane {
        position: fixed;
        z-index: 9999999 !important;
    }
}
}
.cdk-overlay-container {
  /* Do you changes here */
  position: fixed;
  z-index: 99999 !important;
}
.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    .cdk-overlay-pane {
        position: fixed;
        z-index: 9999999 !important;
    }
}
.cal-icon {
  position: absolute;
  top: 36px;
  right: 18px;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none !important;
}
.form-text {
  color: #ed5050 !important;
}
.navbar-expand-lg .dropdown:hover > .dropdown-menu {
  display: block;
  animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media (max-width: 768px) {
.responsive-tab {
  .nav-link {
    padding: 5px 7px !important;
    font-size: 10px !important;
  }
}
table {
  thead tr th,
  tbody tr td {
    font-size: 10px !important;
    vertical-align: middle;
  }
}
.card-body {
  padding: 1rem !important;
}
.my-nav {
  .nav-link {
      font-size: 12px !important;
      margin: 10px 8px !important;
  }
}
}
.my-text-ellp-135 {
  width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Chat

.add-file-preview {
  width: 100px;
  height: 100px;
  border:1px solid #d4d4d4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 2px;
  margin: 10px 0 0 10px;
  .file-name {
    font-size: 14px;
    width: 80px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
  .ai-file {
    font-size: 40px;
    display: inline-block;
  }
  .file-type {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 10px;
    font-weight: 900;
  }
  .file-cross {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.message-box-start, .message-box-end {
  white-space: pre-line;
  line-break: anywhere;
}
.file-download {
  position: relative;
}
.file-download-icon-left {
  position: absolute;
  z-index: 999;
  right: -33px;
  top: 15px;
  cursor: pointer;
}
.file-download-icon-right {
  position: absolute;
  z-index: 999;
  left: -33px;
  top: 15px;
  cursor: pointer;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container .dp-calendar-nav-container-left, dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container .dp-calendar-nav-container-right {
  height: auto !important;
}
.fc-scroller {
  overflow: auto !important;
}
.fc-scrollgrid.table-bordered{
  border-color: transparent !important;
}
.fc-daygrid, .fc-timegrid, .fc-list {
  padding: 0.3rem !important;
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
  height: 200px;
  overflow: auto;
}
.cursor-pointer {
  cursor: pointer !important;
}





header .head-line {
  margin: -30px 0 30px;
}
.head-line {
  transition: all .3s ease;
  max-height: 100px;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .head-line {
      padding: 2px 15px;
      font-size: 11px;
  }
}
.head-line {
  padding: 8px 15px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  transition: all .3s;
}
.navbar.navbar-stuck {
  margin-top: 0 !important;
}
.message-notification-count {
  position: absolute;
  top: -5px;
  right: 90px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-bs-theme=dark] .hover-user:hover {
  background: #ffffff0f;
  cursor: pointer;
}
[data-bs-theme=light] .hover-user:hover {
  background: #edf1f5;
  cursor: pointer;
}
.simplebar-content {
  padding: 1rem !important;
}