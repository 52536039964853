//
// Custom scrollbar
// based on https://github.com/Grsmto/simplebar
// --------------------------------------------------


[data-simplebar] {
  --#{$prefix}scrollbar-width: #{$scrollbar-width};
  --#{$prefix}scrollbar-bg: #{$scrollbar-bg};
  --#{$prefix}scrollbar-border-radius: #{$scrollbar-border-radius};
  --#{$prefix}scrollbar-track-width: #{$scrollbar-track-width};
  --#{$prefix}scrollbar-track-bg: #{$scrollbar-track-bg};
}

.simplebar-scrollbar {
  &::before {
    right: 0 !important;
    left: 0 !important;
    background-color: var(--#{$prefix}scrollbar-bg) !important;
    @include border-radius(var(--#{$prefix}scrollbar-border-radius));
    opacity: 1 !important; // stylelint-disable-line declaration-no-important
    transition: none !important;
  }
}

.simplebar-track {
  overflow: initial !important;
  background-color: var(--#{$prefix}scrollbar-track-bg) !important;
  @include border-radius(var(--#{$prefix}scrollbar-border-radius));
  opacity: 0 !important;
  transition: opacity .15s ease-in-out !important;

  &.simplebar-vertical {
    right: calc(var(--#{$prefix}scrollbar-width) * .5) !important;
    width: var(--#{$prefix}scrollbar-track-width) !important;
    .simplebar-scrollbar {
      left: 50% !important;
      width: var(--#{$prefix}scrollbar-width) !important;
      margin-left: calc(var(--#{$prefix}scrollbar-width) * -.5) !important;
      &::before {
        top: 0 !important;
        bottom: 0 !important;
      }
    }
  }

  &.simplebar-horizontal {
    bottom: calc(var(--#{$prefix}scrollbar-width) * .5) !important;
    height: var(--#{$prefix}scrollbar-track-width) !important;
    .simplebar-scrollbar {
      top: 50% !important;
      bottom: 0 !important;
      height: var(--#{$prefix}scrollbar-width) !important;
      margin-top: calc(var(--#{$prefix}scrollbar-width) * -.5) !important;
      &::before {
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
      }
    }
  }
}

.simplebar-wrapper:hover ~ .simplebar-track,
[data-simplebar-auto-hide="false"] .simplebar-track {
  opacity: 1 !important;
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    [data-simplebar] {
      --#{$prefix}scrollbar-bg: #{$scrollbar-bg-dark} !important;
      --#{$prefix}scrollbar-track-bg: #{$scrollbar-track-bg-dark} !important;
    }
  }
}
